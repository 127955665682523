import React, { useState } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

import Layout from "../components/Layout"
import Meta from "../components/Meta"

import useForm from "../utils/useForm"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import MobileMenu from "../components/MobileMenu"
import PageTitle from "../components/ui/PageTitle"
import Banner from "../components/Banner"

import photosIcon from "../../static/photos-icon.svg"

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  .left-side,
  .right-side {
    width: 48%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 100%;
    }
    &.left-side {
      .contact-btn {
        max-width: 185px;
      }
      #enquiries-form {
        display: flex;
        flex-direction: column;

        textarea {
          resize: vertical;
          padding: 8px !important;
          min-height: 70px;
          height: 100%;
          background: #2e2e2e;
          color: var(--white);
          border: none;
          margin-bottom: 20px;
          padding: 0 8px;
          border-radius: 4px;
          font-weight: 300;
          transition: 0.2s ease-in;
          caret-color: var(--red);
          &::placeholder {
            color: #a9a9a9;
            font-size: 13px;
            font-weight: 300;
          }
        }

        .phone {
          width: 42% !important;
          @media (max-width: 600px) {
            width: 100% !important;
          }
        }

        textarea:focus,
        input:focus {
          outline: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        *:focus {
          outline: 0;
        }
        input {
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        input:not([type="file"]),
        select {
          background: #2e2e2e;
          color: var(--white);
          height: 36px;
          border: none;
          margin-bottom: 20px;
          padding: 0 8px;
          border-radius: 4px;
          font-weight: 300;
          transition: 0.2s ease-in;
          caret-color: var(--red);
          &::placeholder {
            color: #a9a9a9;
            font-size: 13px;
            font-weight: 300;
          }
          &::active {
            border: none;
          }
        }
        input[name="name"] {
          width: calc(100% - 20px) !important;
        }
        select {
          color: #a9a9a9;
          font-size: 13px;
          font-weight: 300;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url("../../dropdown.svg");
          background-repeat: no-repeat, repeat;
          background-position: right 0.5em top 50%, 0 0;
          background-size: 1.1em auto, 100%;
        }
        legend {
          margin: 0 0 15px 0;
          padding-top: 15px;
          color: var(--red);
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2em;
          user-select: none;
        }
        fieldset {
          display: flex;
          flex-direction: column;
          padding: 0;
          position: relative;
          margin-bottom: 30px;
          &::last-of-type {
            margin-bottom: 0;
          }
          #darkTst {
            display: none;
          }
          .full {
            width: calc(100% - 16px);
          }
          .half-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media (max-width: 768px) {
              flex-wrap: wrap;
            }
            .half {
              width: 45%;
              @media (max-width: 600px) {
                width: 100%;
              }
            }
          }
        }
        .submit-fieldset {
          display: block;
          margin-bottom: 40px;
          @media (max-width: 600px) {
            display: flex;
          }
          .submit-form-btn {
            &.disabled {
              background: unset;
              &:hover {
                background: unset;
                cursor: not-allowed;
              }
            }
          }
          .message {
            margin: -30px 0 20px 0;
            color: var(--white);
            &.error {
              color: var(--red);
            }
          }
        }
        /* fix to hide choose file  */
        input[type="file"]::-webkit-file-upload-button {
          display: none;
        }
        .upload-wrap {
          height: 36px;
          width: calc(100% - 16px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #2e2e2e;
          color: var(--white);
          border: none;
          margin-bottom: 20px;
          padding: 0 8px;
          border-radius: 4px;
          font-weight: 300;
          transition: 0.2s ease-in;
          caret-color: var(--red);
          cursor: pointer;
          .upload-left {
            span {
              margin-right: 20px;
              color: #a9a9a9;
              font-size: 13px;
              font-weight: 300;
              cursor: pointer;
            }
            .upload-input {
              color: #a9a9a9;
              font-size: 13px;
              font-weight: 300;
              cursor: pointer;
            }
          }
          img {
            max-width: 17px;
          }
        }
      }
    }
    &.right-side {
      align-items: center;
      .right-side-image {
        max-width: 70%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        @media (max-width: 768px) {
          max-width: 50%;
        }
      }
    }
  }
`

export default function EnquiriesPage({ data }) {
  const [message, setMessage] = useState(""),
    [hasError, setHasError] = useState(false),
    [loading, setLoading] = useState(false),
    [uploadedFile, setUploadedFile] = useState()

  const { sanityEnquiries, sanityDetails } = data,
    enqData = sanityEnquiries,
    details = sanityDetails

  const { values, updateValue } = useForm({
    name: "",
    phone: "",
    email: "",
    darkTst: "",
    service: "",
    placement: "",
    size: "",
    file: "",
  })

  const handleSuccess = () => {
    setMessage(
      `Thank you! Your enquiry has been successfully submitted. Our team will respond as soon as possible.`
    )
    setHasError(false)
    console.log("Form submitted.")
  }

  const handleFailure = e => {
    e && console.error(e)
    setHasError(true)
    setMessage(`Submission failed. Please call, email us or try again later.`)
  }

  const submitForm = e => {
    e.preventDefault()
    setLoading(true)

    let myForm = document.getElementById("enquiries-form")
    let formData = new FormData(myForm)
    const MAX_FILE_SIZE = 1024 * 9 // 9MB

    // check for unwanted requests
    if (values.darkTst.length > 0) {
      console.log("Found you!")
    } else {
      if (
        enqData.uploadEnabled &&
        uploadedFile.size &&
        uploadedFile.size / 1024 > MAX_FILE_SIZE
      ) {
        const size = uploadedFile.size / 1024
        setHasError(true)

        setMessage(
          `Submission failed: Max allowed image size is 9MB, your file has over ${
            Array.from(size.toString())[0]
          }MB. Please resize or replace it.`
        )
      } else {
        handleSuccess()
        fetch("/", {
          method: "POST",
          body: formData,
          // headers: { "Content-Type": "application/x-www-form-urlencoded" },
          // body: new URLSearchParams(formData).toString(),
        })
          .then(() => handleSuccess())
          .catch(error => handleFailure(error))
      }
    }

    setLoading(false)
  }

  const handleFileChange = event => {
    if (event.target.files.length > 0) {
      setUploadedFile(event.target.files[0])
    }
  }
  return (
    <div className="page">
      <Meta title="Enquiries" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={enqData.pageTitle} />
          <Banner data={enqData.bannerText} image={enqData.bannerBackground} />
          <StyledContent>
            <div className="left-side">
              {enqData.formSubmissionEnabled ? (
                <form
                  name="enquiries"
                  method="POST"
                  data-netlify="true"
                  onSubmit={submitForm}
                  id="enquiries-form"
                  // If forms stopped working - re enable
                  // enctype="multipart/form-data"
                >
                  <input type="hidden" name="form-name" value="enquiries" />
                  <fieldset>
                    <legend>Personal Info</legend>
                    <input
                      disabled={loading}
                      type="text"
                      name="name"
                      id="name"
                      required
                      value={values.name}
                      onChange={updateValue}
                      placeholder="Your name *"
                      className="full"
                    />

                    <div className="half-wrap">
                      <input
                        disabled={loading}
                        type="number"
                        name="phone"
                        id="phone"
                        required
                        value={values.phone}
                        onChange={updateValue}
                        placeholder="Your phone number (numbers only) *"
                        className="half phone"
                      />

                      <input
                        disabled={loading}
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={values.email}
                        onChange={updateValue}
                        placeholder="Your email *"
                        className="half"
                      />
                    </div>

                    <input
                      type="darkTst"
                      name="darkTst"
                      id="darkTst"
                      value={values.darkTst}
                      onChange={updateValue}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Tattoo info</legend>
                    <select
                      name="service"
                      required
                      disabled={loading}
                      value={values.service}
                      onChange={updateValue}
                    >
                      <option value="" disabled hidden>
                        What service are you requesting? *
                      </option>
                      <option value="New Tattoo">New tattoo</option>
                      <option value="Re-touch my old Tattoo">
                        Re-touch my old tattoo
                      </option>
                      <option value="Cover my old tattoo">
                        Cover my old tattoo
                      </option>
                    </select>
                    <div className="half-wrap">
                      <select
                        name="placement"
                        required
                        disabled={loading}
                        value={values.placement}
                        onChange={updateValue}
                        className="half"
                      >
                        <option value="" disabled hidden>
                          Placement *
                        </option>
                        <option value="Neck">Neck</option>
                        <option value="Chest">Chest</option>
                        <option value="Stomach">Stomach</option>
                        <option value="Upper arm">Upper arm</option>
                        <option value="Inner bicep">Inner bicep</option>
                        <option value="Other">Other</option>
                      </select>
                      <input
                        disabled={loading}
                        type="text"
                        name="size"
                        id="size"
                        value={values.size}
                        onChange={updateValue}
                        placeholder="Size (e.g. 5x7, palm-sized, etc)"
                        className="half item"
                      />
                    </div>
                    {/* TODO: Limit file size */}
                    {enqData.uploadEnabled && (
                      <label className="upload-wrap">
                        <div className="upload-left">
                          <span>Add photos (max 9MB)</span>
                          <input
                            disabled={loading}
                            name="file"
                            type="file"
                            // multiple
                            id="upload-images"
                            accept="image/*"
                            className="upload-input"
                            onChange={handleFileChange}
                          />
                        </div>
                        <img src={photosIcon} alt="dark-spectrum-upload-icon" />
                      </label>
                    )}
                    <textarea
                      id="description"
                      name="description"
                      placeholder="Please describe the tattoo, preferred style, colours, etc."
                      value={values.description}
                      onChange={updateValue}
                    />
                  </fieldset>
                  <fieldset className="submit-fieldset">
                    <div className={hasError ? "error message" : "message"}>
                      {message}
                    </div>
                    <button
                      type="submit"
                      className={
                        loading ? "submit-form-btn disabled" : "submit-form-btn"
                      }
                      disabled={loading}
                    >
                      <span aria-live="assertive" aria-atomic="true">
                        {loading ? "Submitting..." : "Submit Enquiry"}
                      </span>
                    </button>
                  </fieldset>
                </form>
              ) : (
                <>
                  <p style={{ marginBottom: "20px" }}>
                    {enqData.disabledFormMessage}
                  </p>
                  <button
                    className="contact-btn"
                    onClick={e => {
                      navigate(`/contact`)
                    }}
                  >
                    See our contact details
                  </button>
                </>
              )}
            </div>
            <div className="right-side">
              <Image
                alt={"enquiries-dark-spectrum-tattoo"}
                className={"right-side-image"}
                width={700}
                {...enqData.sideImage}
              />
            </div>
          </StyledContent>
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const enquiriesPage = graphql`
  query EnquiriesQuery {
    sanityEnquiries {
      bannerBackground {
        asset {
          id
          publicUrl
          url
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      bannerText {
        children {
          _key
          _type
          text
          marks
        }
        _type
        list
        style
        _key
      }
      pageTitle
      uploadEnabled
      formSubmissionEnabled
      disabledFormMessage
      sideImage {
        ...ImageWithPreview
      }
    }
    sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
    }
  }
`
